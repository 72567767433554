<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="approvalModal"
        ref="approvalModal"
        size="lg"
        :title="'Approuver la requête ' + this.$props.modelRef"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          
          
          <div class="row">
            <template v-if="requestData">
              <div v-if="requestData.needHosting" class="col-md-12">
                <label for="formrow-inputCity">Site *</label>
                <multiselect
                    v-model="aprovalForm.campId"
                    :searchable="true"
                    track-by="id"
                    label="name"
                    :options="camps"
                    placeholder="Sélectionner le site"
                    :allow-empty="false"
                    :class="{'is-invalid': submitted && $v.aprovalForm.campId.$error,}"
                  >
                    <template slot="singleCamp" slot-scope="{ camp }">{{
                      camp.name
                    }}</template>
                  </multiselect>
                  <div v-if="submitted && $v.aprovalForm.campId.$error" class="invalid-feedback">
                    <span v-if="!$v.aprovalForm.campId.required">Champ Obligatoire.</span>
                  </div>
              </div>
            </template>
            
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires *</label>
                <textarea
                  v-model="aprovalForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-primary float-right" type="submit">
                Valider
              </button>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  props: {
    modelUid: {
      type: Number,
    },
    modelRef: {
      type: String,
    },
    requestData: null,
    camps: undefined,
  },
  components: {Multiselect},
  data() {
    return {
      show: true,
      submitted: false,
      aprovalForm: {
        requestUid: "",
        campId: "",
        comments: "",
      },
    };
  },
  methods: {
    submit() {
        this.aprovalForm.requestUid = this.$props.modelUid;
        var _this = this;
        this.submitted = true;
        this.$v.$touch();
        if (!this.$v.$invalid){
          var loader = this.$loading.show();
          this.$http
          .post("/ops/requests/visitors_support/approve", this.aprovalForm)
  
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                loader.hide();
                window.location.reload();
                _this.$toast.success(res.data.original.msg);
                break;
  
              case 500:
                loader.hide();
                _this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            _this.$toast.warning(error.message);
          })
          .finally(() => {});
        }
    },
  },

  validations: {
    aprovalForm: {
      
      campId: {
        required: requiredIf(function() {
          return this.requestData.needHosting;
        }),
      },
    },
  },
};
</script>
